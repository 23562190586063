export default [
  {
    path: '/app-content/tv-tutorial',
    name: 'tv-tutorial',
    component: () => import('@/views/app-content/tv-tutorial.vue'),
    meta: {
      resource: 'global-TVExplanation',
      action: 'update',
      breadcrumb: [
        {
          text: 'MuslimDo Tutorial',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app-content/fundraising-ads',
    name: 'fundraising-ads',
    component: () => import('@/views/app-content/fundraising-ads.vue'),
    meta: {
      resource: 'global-AdsSliderController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Fundraising Ads',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app-content/event-ads',
    name: 'event-ads',
    component: () => import('@/views/app-content/event-ads.vue'),
    meta: {
      resource: 'global-AdsSliderController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Event Ads',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app-content/offer-ads',
    name: 'offer-ads',
    component: () => import('@/views/app-content/offer-ads.vue'),
    meta: {
      resource: 'global-AdsSliderController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Offer Ads',
          active: true,
        },
      ],
    },
  },
]
