export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register-organizer',
    name: 'register-organizer',
    component: () => import('@/views/auth/register-organizer.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: () => import('@/views/auth/changePassword.vue'),
    meta: {
      resource: 'Auth',
      action: 'change-password',
    },
  },
  {
    path: '/reset-password-request',
    name: 'reset-password-request',
    component: () => import('@/views/auth/resetPasswordRequest.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/views/auth/resetPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register/read-more',
    name: 'register-read-more',
    component: () => import('@/views/auth/read-more.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
      redirectIfLoggedIn: true,
    },
  },
]
