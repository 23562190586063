import Vue from 'vue'
import { ToastPlugin, ModalPlugin, BootstrapVue } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueSweetalert2 from 'vue-sweetalert2'
import {
  ValidationProvider, ValidationObserver,
} from 'vee-validate'
import moment from 'vue-moment'
import IdleVue from 'idle-vue'
import '@/libs/font-awesome'
import './libs/vee-validate-setup'
import VueCryptojs from 'vue-cryptojs'
import axiosInstances from '@/libs/axios-instances'
import router from './router'
import store from './store'
import App from './App.vue'
// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

import '@/libs/baseUrlsFromEnv'

import '@/libs/acl'

import { messaging } from './firebase'

Vue.prototype.$messaging = messaging

Vue.use(VueCryptojs)

// register idleVue
const eventsHub = new Vue()

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 3600000, // 3600000 = 1 hour
  startAtIdle: false,
})

// register axios apis instances
Vue.use(axiosInstances)

Vue.use(moment)

// vee validate register
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVue)

// Composition API
Vue.use(VueCompositionAPI)

Vue.use(VueSweetalert2)
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard
// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
