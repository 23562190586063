import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn } from '@/auth/utils'

import business from '@/router/routes/business'
import announcements from '@/router/routes/announcements'
import liveManagement from '@/router/routes/live-management'
import prayerBookings from '@/router/routes/prayer-booking'
import prayers from '@/router/routes/prayers'
import generalSettings from '@/router/routes/general-settings'
import notifications from '@/router/routes/notifications'
import entityPermissions from '@/router/routes/entity-permissions'
import attendance from '@/router/routes/attendance'
import transactions from '@/router/routes/transactions'
import mobileUpdates from '@/router/routes/mobile-updates'
import polls from '@/router/routes/polls'
import topicsCalendar from '@/router/routes/topics-calendar'
import quickEvents from '@/router/routes/quick-events'
import inpo from './routes/inpo'
import dashboard from './routes/dashboard'
import auth from './routes/auth'
import mobileUsers from './routes/mobile-users'
import eventOrganizer from './routes/event-organizer'
import entityProfile from './routes/entity-profile'
import fundraising from './routes/fundraising'
import adminPermissions from './routes/admin-permissions'
import communityEvents from './routes/community-events'
import appContent from './routes/app-content'
import offers from './routes/offers'
import organizationSuggestions from './routes/organization-suggestions'
import azkar from './routes/azkar'
import azkarCategory from './routes/azkarCategory'
import suggestedOffers from './routes/suggested-offers'
import businessOffers from './routes/business-offers'
import suggestedEvents from './routes/suggested-events'
import users from './routes/users'
import reclaims from './routes/reclaims'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        resource: 'Entity',
        action: 'select-entity',
      },
    },
    {
      path: '/pages/not-authorized',
      name: 'not-authorized',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        action: 'read',
        resource: 'Auth',
      },
    },
    {
      path: '*',
      component: () => import('@/views/error/Error404.vue'),
    },
    ...mobileUsers,
    ...auth,
    ...dashboard,
    ...inpo,
    ...business,
    ...announcements,
    ...liveManagement,
    ...prayerBookings,
    ...prayers,
    ...generalSettings,
    ...notifications,
    ...entityPermissions,
    ...attendance,
    ...transactions,
    ...mobileUpdates,
    ...polls,
    ...topicsCalendar,
    ...quickEvents,
    ...eventOrganizer,
    ...entityProfile,
    ...fundraising,
    ...adminPermissions,
    ...communityEvents,
    ...appContent,
    ...offers,
    ...organizationSuggestions,
    ...azkar,
    ...azkarCategory,
    ...suggestedOffers,
    ...suggestedEvents,
    ...businessOffers,
    ...users,
    ...reclaims,
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'login' })

    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }
  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/')
  }

  return next()
})

export default router
