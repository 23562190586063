export default [
  {
    path: '/business-offers/list',
    name: 'business-offers-list',
    component: () => import('@/views/business-offers/list.vue'),
    meta: {
      resource: 'global-CommunityOfferController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Business Offers',
          active: true,
        },
      ],
    },
  },
  {
    path: '/business-offers/add',
    name: 'business-offers-add',
    component: () => import('@/views/business-offers/add.vue'),
    meta: {
      resource: 'global-CommunityOfferController',
      action: 'store',
      breadcrumb: [
        {
          text: 'Business Offers',
          to: { name: 'business-offers-list' },
        },
        {
          text: 'Add Business Offer',
          active: true,
        },
      ],
    },
  },
]
