export default [
  {
    path: '/organization-dash/prayers',
    name: 'prayers-time-list',
    component: () => import('@/views/prayers/PrayersList.vue'),
    meta: {
      resource: 'EntityPrayer',
      action: 'show',
      breadcrumb: [
        {
          text: 'Prayers',
          active: true,
        },
      ],
    },
  },
  {
    path: '/organization-dash/get-azan',
    name: 'get-azan',
    component: () => import('@/views/prayers/getAzan.vue'),
    meta: {
      resource: 'EntityPrayer',
      action: 'show',
      breadcrumb: [
        {
          text: 'Get Azan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/organization-dash/update-iqama',
    name: 'update-iqama',
    component: () => import('@/views/prayers/UpdateIqama.vue'),
    meta: {
      resource: 'EntityPrayer',
      action: 'modify',
      breadcrumb: [
        {
          text: 'Update Iqama',
          active: true,
        },
      ],
    },
  },
  {
    path: '/organization-dash/import',
    name: 'import',
    component: () => import('@/views/prayers/import.vue'),
    meta: {
      resource: 'EntityPrayer',
      action: 'import',
      breadcrumb: [
        {
          text: 'Import Prayers',
          active: true,
        },
      ],
    },
  },
  {
    path: '/organization-dash/export',
    name: 'export',
    component: () => import('@/views/prayers/export.vue'),
    meta: {
      resource: 'EntityPrayer',
      action: 'export',
      breadcrumb: [
        {
          text: 'Export Prayers',
          active: true,
        },
      ],
    },
  },
  {
    path: '/countries-settings/list',
    name: 'country-settings-list',
    component: () => import('@/views/prayers/countries-settings/list.vue'),
    meta: {
      resource: 'prayers',
      action: 'list-country-settings',
      breadcrumb: [
        {
          text: 'Countries Settings',
          active: true,
        },
      ],
    },
  },
  {
    path: '/countries-settings/edit/:id',
    name: 'country-settings-edit',
    component: () => import('@/views/prayers/countries-settings/edit.vue'),
    meta: {
      resource: 'prayers',
      action: 'edit-country-settings',
      breadcrumb: [
        {
          text: 'Countries Settings',
          to: { name: 'country-settings-list' },
        },
        {
          text: 'Edit Country Settings',
          active: true,
        },
      ],
    },
  },
]
