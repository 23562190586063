export default [
  {
    path: '/quick-events/list',
    name: 'quick-events-list',
    component: () => import('@/views/quick-events/list.vue'),
    meta: {
      resource: 'EventController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Events',
          active: true,
        },
      ],
    },
  },
  {
    path: '/quick-events/add',
    name: 'quick-event-add',
    component: () => import('@/views/quick-events/add.vue'),
    meta: {
      resource: 'EventController',
      action: 'store',
      breadcrumb: [
        {
          text: 'Events',
          to: { name: 'quick-events-list' },
        },
        {
          text: 'Add Event',
          active: true,
        },
      ],
    },
  },
  {
    path: '/quick-events/edit/:id',
    name: 'quick-event-edit',
    component: () => import('@/views/quick-events/edit.vue'),
    meta: {
      resource: 'EventController',
      action: 'update',
      breadcrumb: [
        {
          text: 'Events',
          to: { name: 'quick-events-list' },
        },
        {
          text: 'Edit Event',
          active: true,
        },
      ],
    },
  },
  {
    path: '/quick-events/add-single-session/',
    name: 'quick-event-add-single-session',
    component: () => import('@/views/quick-events/add-single-session-event.vue'),
    meta: {
      resource: 'EventController',
      action: 'store',
      breadcrumb: [
        {
          text: 'Events',
          to: { name: 'quick-events-list' },
        },
        {
          text: 'Add Event',
          active: true,
        },
      ],
    },
  },
  {
    path: '/quick-events/view/:id',
    name: 'quick-event-view',
    component: () => import('@/views/quick-events/view.vue'),
    meta: {
      resource: 'EventController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Events',
          to: { name: 'quick-events-list' },
        },
        {
          text: 'View Event',
          active: true,
        },
      ],
    },
  },
  {
    path: '/quick-events/:eventId/sessions/list',
    name: 'quick-event-sessions-list',
    component: () => import('@/views/quick-events/sessions/list.vue'),
    meta: {
      resource: 'EventController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Events',
          to: { name: 'quick-events-list' },
        },
        {
          text: 'Sessions',
          active: true,
        },
      ],
    },
  },
  {
    path: '/quick-events/:eventId/sessions/add/',
    name: 'quick-event-session-add',
    component: () => import('@/views/quick-events/sessions/add.vue'),
    meta: {
      resource: 'EventController',
      action: 'store',
      breadcrumb: [
        {
          text: 'Events',
          to: { name: 'quick-events-list' },
        },
        {
          text: 'Add Session',
          active: true,
        },
      ],
    },
  },
  {
    path: '/quick-events/:eventId/sessions/edit/:id',
    name: 'quick-event-session-edit',
    component: () => import('@/views/quick-events/sessions/edit.vue'),
    meta: {
      resource: 'EventController',
      action: 'update', // FIXME: apply correct permissions
      breadcrumb: [
        {
          text: 'Events',
          to: { name: 'quick-events-list' },
        },
        {
          text: 'Sessions',
          to: 'back',
          levels: 1,
        },
        {
          text: 'Edit Session',
          active: true,
        },
      ],
    },
  },
  {
    path: '/quick-events/:eventId/sessions/duplicate/:id',
    name: 'quick-event-session-duplicate',
    component: () => import('@/views/quick-events/sessions/duplicate.vue'),
    meta: {
      resource: 'EventController',
      action: 'store',
      breadcrumb: [
        {
          text: 'Events',
          to: { name: 'quick-events-list' },
        },
        {
          text: 'Sessions',
          to: 'back',
          levels: 1,
        },
        {
          text: 'Duplicate Session',
          active: true,
        },
      ],
    },
  },
  {
    path: '/quick-events/:eventId/sessions/view/:id',
    name: 'quick-event-session-view',
    component: () => import('@/views/quick-events/sessions/view.vue'),
    meta: {
      resource: 'EventController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Events',
          to: { name: 'quick-events-list' },
        },
        {
          text: 'View Event',
          to: { name: 'quick-events-view' },
        },
        {
          text: 'Event Sessions',
          active: true,
        },
      ],
    },
  },
  {
    path: '/quick-events/bookings/list',
    name: 'quick-event-bookings-list',
    component: () => import('@/views/quick-events/bookings/list.vue'),
    meta: {
      resource: 'EventController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Bookings',
          active: true,
        },
      ],
    },
  },
  {
    path: '/quick-events/booking/:id/tickets/list',
    name: 'tickets-list',
    component: () => import('@/views/quick-events/bookings/tickets/list.vue'),
    meta: {
      resource: 'EventSessionController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Bookings',
          to: { name: 'quick-event-bookings-list' },
        },
      ],
    },
  },
  {
    path: '/quick-events/:eventId/sessions/:id/bookings/',
    name: 'quick-event-session-bookings',
    component: () => import('@/views/quick-events/sessions/bookings/list.vue'),
    meta: {
      resource: 'BookingRequestController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Events',
          to: { name: 'quick-events-list' },
        },
        {
          text: 'Sessions',
          to: 'back',
          levels: 1,
        },
        {
          text: 'Bookings',
          active: true,
        },
      ],
    },
  },
  {
    path: '/quick-events/scan-ticket',
    name: 'quick-event-scan-ticket',
    component: () => import('@/views/quick-events/attendance/scanQR.vue'),
    meta: {
      resource: 'EventController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Scan Ticket',
          active: true,
        },
      ],
    },
  },
  {
    path: '/quick-events/confirm-attendance/:QRCode',
    name: 'quick-event-confirm-attendance',
    component: () => import('@/views/quick-events/attendance/confirm-attendance.vue'),
    meta: {
      resource: 'EventController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Confirm Attendance',
          active: true,
        },
      ],
    },
  },
]
