export default [
  {
    path: '/entity/announcements/list',
    name: 'announcements-list',
    component: () => import('@/views/announcements/list.vue'),
    meta: {
      resource: 'ActivityController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Announcements',
          active: true,
        },
      ],
    },
  },
  {
    path: '/entity/announcements/add',
    name: 'add-announce',
    component: () => import('@/views/announcements/add.vue'),
    meta: {
      resource: 'ActivityController',
      action: 'store',
      breadcrumb: [
        {
          text: 'Announcements',
          to: { name: 'announcements-list' },
        },
        {
          text: 'Add Announcement',
          active: true,
        },
      ],
    },
  },
  {
    path: '/entity/announcements/view/:id',
    name: 'view-announce',
    component: () => import('@/views/announcements/view.vue'),
    meta: {
      resource: 'ActivityController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Announcements',
          to: { name: 'announcements-list' },
        },
        {
          text: 'View Announcement',
          active: true,
        },
      ],
    },
  },
  {
    path: '/entity/announcements/:id/feedback-reports',
    name: 'announce-feedback-reports',
    component: () => import('@/views/announcements/feedback-reports.vue'),
    meta: {
      resource: 'ActivityController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Announcements',
          to: { name: 'announcements-list' },
        },
        {
          text: 'Announcement Feedback Reports',
          active: true,
        },
      ],
    },
  },
  {
    path: '/entity/announcements/edit/:id',
    name: 'edit-announce',
    component: () => import('@/views/announcements/edit.vue'),
    meta: {
      resource: 'ActivityController',
      action: 'update',
      breadcrumb: [
        {
          text: 'Announcements',
          to: { name: 'announcements-list' },
        },
        {
          text: 'Edit Announcement',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/announcements/list',
    name: 'admin-announcements-list',
    component: () => import('@/views/announcements/admin/list.vue'),
    meta: {
      resource: 'global-ActivityController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Announcements',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/announcements/add',
    name: 'admin-add-announce',
    component: () => import('@/views/announcements/admin/add.vue'),
    meta: {
      resource: 'global-ActivityController',
      action: 'store',
      breadcrumb: [
        {
          text: 'Announcements',
          to: { name: 'admin-announcements-list' },
        },
        {
          text: 'Add Announcement',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/announcements/view/:id',
    name: 'admin-view-announce',
    component: () => import('@/views/announcements/view.vue'),
    meta: {
      resource: 'global-ActivityController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Announcements',
          to: { name: 'admin-announcements-list' },
        },
        {
          text: 'View Announcement',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/announcements/:id/feedback-reports',
    name: 'admin-announce-feedback-reports',
    component: () => import('@/views/announcements/feedback-reports.vue'),
    meta: {
      resource: 'global-ActivityController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Announcements',
          to: { name: 'admin-announcements-list' },
        },
        {
          text: 'Announcement Feedback Reports',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/announcements/edit/:id',
    name: 'admin-edit-announce',
    component: () => import('@/views/announcements/admin/edit.vue'),
    meta: {
      resource: 'global-ActivityController',
      action: 'update',
      breadcrumb: [
        {
          text: 'Announcements',
          to: { name: 'admin-announcements-list' },
        },
        {
          text: 'Edit Announcement',
          active: true,
        },
      ],
    },
  },
]
