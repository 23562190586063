export default [
  {
    path: '/general-settings/tags',
    name: 'tags',
    component: () => import('@/views/general-settings/tags.vue'),
    meta: {
      resource: 'global-TagController',
      action: 'store',
      breadcrumb: [
        {
          text: 'Tags',
          active: true,
        },
      ],
    },
  },
  {
    path: '/general-settings/offer/tags',
    name: 'offer-tags',
    component: () => import('@/views/general-settings/offer-tags.vue'),
    meta: {
      resource: 'global-OfferTagController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Offer Tags',
          active: true,
        },
      ],
    },
  },
  {
    path: '/general-settings/offer/categories',
    name: 'offer-categories',
    component: () => import('@/views/general-settings/offer-categories.vue'),
    meta: {
      resource: 'global-OfferCategoryController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Offer Categories',
          active: true,
        },
      ],
    },
  },
  {
    path: '/general-settings/programs',
    name: 'programs',
    component: () => import('@/views/general-settings/programs.vue'),
    meta: {
      resource: 'global-ProgramController',
      action: 'store',
      breadcrumb: [
        {
          text: 'Programs',
          active: true,
        },
      ],
    },
  },
  {
    path: '/general-settings/products',
    name: 'products',
    component: () => import('@/views/general-settings/products.vue'),
    meta: {
      resource: 'global-ProductController',
      action: 'store',
      breadcrumb: [
        {
          text: 'Products',
          active: true,
        },
      ],
    },
  },
  {
    path: '/general-settings/languages',
    name: 'languages',
    component: () => import('@/views/general-settings/languages.vue'),
    meta: {
      resource: 'global-LanguageController',
      action: 'store',
      breadcrumb: [
        {
          text: 'Languages',
          active: true,
        },
      ],
    },
  },
  {
    path: '/general-settings/classifications',
    name: 'classifications',
    component: () => import('@/views/general-settings/classifications.vue'),
    meta: {
      resource: 'global-ClassificationController',
      action: 'store',
      breadcrumb: [
        {
          text: 'Classifications',
          active: true,
        },
      ],
    },
  },
  {
    path: '/general-settings/subclassifications',
    name: 'sub-classifications',
    component: () => import('@/views/general-settings/sub-classifications.vue'),
    meta: {
      resource: 'global-SubclassificationController',
      action: 'store',
      breadcrumb: [
        {
          text: 'Subclassifications',
          active: true,
        },
      ],
    },
  },
  {
    path: '/general-settings/donation-categories',
    name: 'donation-categories',
    component: () => import('@/views/general-settings/donation-categories.vue'),
    meta: {
      resource: 'global-DonationCategoryController',
      action: 'store',
      breadcrumb: [
        {
          text: 'Donation Categories',
          active: true,
        },
      ],
    },
  },
  {
    path: '/general-settings/event-categories',
    name: 'event-categories',
    component: () => import('@/views/general-settings/event-categories.vue'),
    meta: {
      resource: 'global-EventTypeController',
      action: 'store',
      breadcrumb: [
        {
          text: 'Event Categories',
          active: true,
        },
      ],
    },
  },
  {
    path: '/general-settings/budget-items',
    name: 'budget-items',
    component: () => import('@/views/general-settings/budget-items.vue'),
    meta: {
      resource: 'global-BudgetItemController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Budget Items',
          active: true,
        },
      ],
    },
  },
  {
    path: '/general-settings/facilities',
    name: 'facilities',
    component: () => import('@/views/general-settings/facilities.vue'),
    meta: {
      resource: 'global-FacilityController',
      action: 'store',
      breadcrumb: [
        {
          text: 'Facilities',
          active: true,
        },
      ],
    },
  },
  {
    path: '/general-settings/services',
    name: 'services',
    component: () => import('@/views/general-settings/services.vue'),
    meta: {
      resource: 'global-ServiceController',
      action: 'store',
      breadcrumb: [
        {
          text: 'Services',
          active: true,
        },
      ],
    },
  },
]
