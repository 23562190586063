export default [
  {
    path: '/event-organizers/list',
    name: 'event-organizers-list',
    component: () => import('@/views/event-organizers/list.vue'),
    meta: {
      resource: 'global-EntitiesController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Event Organizers',
          active: true,
        },
      ],
    },
  },
  {
    path: '/event-organizers/add',
    name: 'event-organizer-add',
    component: () => import('@/views/event-organizers/add.vue'),
    meta: {
      resource: 'global-EntitiesController',
      action: 'store',
      breadcrumb: [
        {
          text: 'Event Organizers',
          to: { name: 'event-organizers-list' },
        },
        {
          text: 'Add Event Organizers',
          active: true,
        },
      ],
    },
  },
  {
    path: '/event-organizers/edit/:id',
    name: 'event-organizer-edit',
    component: () => import('@/views/event-organizers/edit.vue'),
    meta: {
      resource: 'EntitiesController',
      action: 'update',
      breadcrumb: [
        {
          text: 'Event Organizers',
          to: { name: 'event-organizers-list' },
        },
        {
          text: 'Edit Event Organizers',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/event-organizers/edit/:id',
    name: 'admin-event-organizer-edit',
    component: () => import('@/views/event-organizers/edit.vue'),
    meta: {
      resource: 'global-EntitiesController',
      action: 'update',
      breadcrumb: [
        {
          text: 'Event Organizers',
          to: { name: 'event-organizers-list' },
        },
        {
          text: 'Edit Event Organizers',
          active: true,
        },
      ],
    },
  },
  {
    path: '/event-organizers/view/:id',
    name: 'event-organizer-view',
    component: () => import('@/views/event-organizers/view.vue'),
    meta: {
      resource: 'global-EntitiesController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Event Organizers',
          to: { name: 'event-organizers-list' },
        },
        {
          text: 'View Event Organizers',
          active: true,
        },
      ],
    },
  },
]
