export default [
  {
    path: '/admin-dashboard',
    name: 'admin-dashboard',
    component: () => import('@/views/dashboard/adminDashboard.vue'),
    meta: {
      resource: 'global-systemAdminDashboard',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Admin Dashboard',
          active: true,
        },
      ],
    },
  },
  {
    path: '/inpo-dashboard',
    name: 'inpo-dashboard',
    component: () => import('@/views/dashboard/view.vue'),
    meta: {
      resource: 'adminDashboard',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Islamic Org Dashboard',
          active: true,
        },
      ],
    },
  },
]
