import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import generalSettings from '@/store/general-settings/generalSettings'
import addEvents from '@/store/prayer-bookings/addEvents'
import mainEntity from '@/store/entity/mainEntity'
import donor from '@/store/donations/donor'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

import auth from './auth/auth'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    generalSettings,
    addEvents,
    mainEntity,
    donor,
  },
  strict: process.env.DEV,
})
