export default [
  {
    path: '/fundraising/list',
    name: 'fundraising-list',
    component: () => import('@/views/fundraising/list.vue'),
    meta: {
      resource: 'FundraisingController',
      action: 'save',
      breadcrumb: [
        {
          text: 'Fundraising Projects',
          active: true,
        },
      ],
    },
  },
  {
    path: '/fundraising/add',
    name: 'fundraising-add',
    component: () => import('@/views/fundraising/add.vue'),
    meta: {
      resource: 'FundraisingController',
      action: 'save',
      breadcrumb: [
        {
          text: 'Fundraising Projects',
          to: { name: 'fundraising-list' },
        },
        {
          text: 'Add Fundraising Project',
          active: true,
        },
      ],
    },
  },
  {
    path: '/fundraising/edit/:id',
    name: 'fundraising-edit',
    component: () => import('@/views/fundraising/edit.vue'),
    meta: {
      resource: 'FundraisingController',
      action: 'save',
      breadcrumb: [
        {
          text: 'Fundraising Projects',
          to: { name: 'fundraising-list' },
        },
        {
          text: 'Edit Fundraising Project',
          active: true,
        },
      ],
    },
  },
  {
    path: '/fundraising/view/:id',
    name: 'fundraising-view',
    component: () => import('@/views/fundraising/view.vue'),
    meta: {
      resource: 'FundraisingController',
      action: 'save',
      breadcrumb: [
        {
          text: 'Fundraising Projects List',
          to: { name: 'fundraising-list' },
        },
        {
          text: 'View Fundraising Projects',
          active: true,
        },
      ],
    },
  },
  {
    path: '/fundraising/feedback-reports/:id',
    name: 'fundraising-feedback-reports',
    component: () => import('@/views/fundraising/feedback-reports.vue'),
    meta: {
      resource: 'FundraisingController',
      action: 'save',
      breadcrumb: [
        {
          text: 'Fundraising Projects List',
          to: { name: 'fundraising-list' },
        },
        {
          text: 'View Fundraising Feedback Reports',
          active: true,
        },
      ],
    },
  },
  {
    path: '/fundraising/donations/:id',
    name: 'fundraising-donations-list',
    component: () => import('@/views/fundraising/project-donations.vue'),
    meta: {
      resource: 'FundraisingController',
      action: 'save',
      breadcrumb: [
        {
          text: 'Fundraising Projects List',
          to: { name: 'fundraising-list' },
        },
        {
          text: 'Fundraising Donations',
          active: true,
        },
      ],
    },
  },
]
