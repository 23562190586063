export default [
  {
    path: '/islamic-suggestions/list',
    name: 'islamic-suggestions-list',
    component: () => import('@/views/organization-suggestions/islamic-list.vue'),
    meta: {
      resource: 'global-EntitiesController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Islamic Organization Suggestions',
          active: true,
        },
      ],
    },
  },
  {
    path: '/business-suggestions/list',
    name: 'business-suggestions-list',
    component: () => import('@/views/organization-suggestions/business-list.vue'),
    meta: {
      resource: 'global-EntitiesController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Business Suggestions',
          active: true,
        },
      ],
    },
  },
  {
    path: '/org-suggestion/edit/:id',
    name: 'organization-suggestion-edit',
    component: () => import('@/views/organization-suggestions/edit.vue'),
    meta: {
      resource: 'global-EntitiesController',
      action: 'update',
      breadcrumb: [
        {
          text: 'Suggestions',
          to: 'back',
          levels: 1,
        },
        {
          text: 'Edit Suggestion',
          active: true,
        },
      ],
    },
  },
  {
    path: '/org-suggestion/view/:id',
    name: 'organization-suggestion-view',
    component: () => import('@/views/organization-suggestions/view.vue'),
    meta: {
      resource: 'global-EntitiesController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Suggestions',
          to: 'back',
          levels: 1,
        },
        {
          text: 'View Suggestion',
          active: true,
        },
      ],
    },
  },
]
