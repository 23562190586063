import { extend, localize } from 'vee-validate'
import ar from 'vee-validate/dist/locale/ar.json'
import en from 'vee-validate/dist/locale/en.json'
import * as rules from 'vee-validate/dist/rules'
import {
  validatorPositive, validatorUrlValidator, validatorPassword, validatorCreditCard,
} from '@/@core/utils/validations/validators'
import validateImages from './validate-images'

const customRules = {
  phone: {
    message: 'The {_field_} may only contain numbers and +',
    validate(value) {
      const regex = /^\+{0,1}(?![a-z A-Z])[0-9]+$/
      if (regex.test(value)) return true
      return false
    },
  },
  alpha_spaces_num: {
    message: 'The {_field_} may only contain alphabetic characters, spaces and numbers',
    validate(value) {
      const regex = /^[A-Za-z0-9 .]+$/
      if (regex.test(value)) return true
      return false
    },
  },
  'youtube-url': value => {
    const regex = /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?(?=.*v=((\w|-){11}))(?:\S+)?$/
    const shortsRegex = /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/shorts\/.{11}/
    if (regex.test(value) || shortsRegex.test(value)) return true
    return 'This Link isn\'t a valid Youtube Url'
  },
  'youtube-channel': value => {
    const regex = /(https?:\/\/)?(www\.)?youtube\.com\//
    if (regex.test(value)) return true
    return 'This Link isn\'t a valid Youtube Channel Url'
  },
  'facebook-url': value => {
    const regex = /(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\\.]*\/)*([\w\-\\.]*)/
    if (regex.test(value)) return true
    return 'This isn\'t a valid Facebook Url'
  },

  'twitter-url': value => {
    const regex = /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/
    if (regex.test(value)) return true
    return 'This isn\'t a valid Twitter Url'
  },

  'instagram-url': value => {
    const regex = /(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_\\.]+)/
    if (regex.test(value)) return true
    return 'This isn\'t a valid Instagram Url'
  },

  'max-dimension': {
    params: ['imageWidth', 'imageHeight'],
    message: 'The {_field_} must be UP TO {imageWidth} pixels by {imageHeight} pixels',

    validate: (images, { imageWidth, imageHeight }) => validateImages(images, image => image.width <= imageWidth && image.height <= imageHeight),
  },
  'min-dimension': {
    params: ['imageWidth', 'imageHeight'],
    message: 'The {_field_} must be Minimum {imageWidth} pixels by {imageHeight} pixels',

    validate: (images, { imageWidth, imageHeight }) => validateImages(images, image => image.width >= imageWidth && image.height >= imageHeight),
  },
  'image-aspect-ratio': {
    params: ['imageWidth', 'imageHeight'],
    message: 'The {_field_} ratio must be {imageWidth}:{imageHeight}',

    validate: (images, { imageWidth, imageHeight }) => validateImages(images, image => {
      const { width, height } = image
      const actualRatio = (width / height).toFixed(2)
      const validationRatio = (imageWidth / imageHeight).toFixed(2)
      return actualRatio === validationRatio
    }),
  },

  positive: {
    validate: validatorPositive,
    message: 'Please enter positive number!',
  },

  'credit-card': {
    validate: validatorCreditCard,
    message: 'It is not valid credit card!',
  },

  password: {
    validate: validatorPassword,
    message: 'Your {_field_} minimum length 8 and should contain at least one uppercase, one lowercase, one special character and one digit',
  },

  url: {
    validate: validatorUrlValidator,
    message: 'URL is invalid',
  },
}

const allRules = { ...customRules, ...rules }

// for every rule we defined above.
Object.keys(allRules).forEach(rule => {
  //  add the rule.
  extend(rule, allRules[rule])
})

const enMessages = { ...en.messages, max: '{_field_} shouldn’t be greater than {length}', required: '{_field_} is a required field' }

localize({
  en: {
    messages: enMessages,
    names: {
      email: 'Email',
      password: 'Password',
    },
    fields: {
      password: {
        min: '{_field_} is too short, you want to get hacked?',
      },
    },
  },
  ar: {
    messages: ar.messages,
    names: {
      email: 'البريد الإلكتروني',
      password: 'كلمة السر',
    },
    fields: {
      password: {
        min: 'كلمة السر قصيرة جداً سيتم اختراقك',
      },
    },
  },
})
