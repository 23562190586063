function resolveImage(file, callback) {
  return new Promise(resolve => {
    const reader = new FileReader()
    const image = new Image()
    reader.onload = e => {
      image.onload = () => {
        resolve(callback(image))
      }
      image.src = e.currentTarget.result
    }
    reader.readAsDataURL(file)
  })
}

export default function validateImages(images, callback) {
  const promises = []
  const files = Array.isArray(images) ? images : [images]

  files.forEach(image => {
    const promise = resolveImage(image, callback)
    promises.push(promise)
  })

  return Promise.all(promises).then(filesResult => filesResult.every(result => result))
}
