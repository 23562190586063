export default function permissionsParser() {
  const separatePermissionsSlug = permissions => {
    const separatedPermissions = []

    permissions.forEach(ele => {
      const [subject, action] = ele.split('.')
      separatedPermissions.push({ subject, action })
    })

    return separatedPermissions
  }

  const formatPermissions = permissionsGroups => {
    const newPermissions = permissionsGroups.map(permissionsGroup => permissionsGroup.permissions).flat().map(permissions => permissions.name)
    const separatedPermissions = separatePermissionsSlug(newPermissions)

    return separatedPermissions
  }
  return { formatPermissions }
}
