export default [
  {
    path: '/reclaims/list',
    name: 'reclaims-list',
    component: () => import('@/views/reclaims/list.vue'),
    meta: {
      resource: 'global-ScrappedEventController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Reclaims',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reclaims/add',
    name: 'reclaimed-org',
    component: () => import('@/views/reclaims/add.vue'),
    meta: {
      resource: 'global-ScrappedEventController',
      action: 'store',
      breadcrumb: [
        {
          text: 'Reclaims',
          to: { name: 'reclaims-list' },
        },
        {
          text: 'Reclaim Org',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reclaims/:id/view',
    name: 'reclaimed-org-view',
    component: () => import('@/views/reclaims/view.vue'),
    meta: {
      resource: 'global-ScrappedEventController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Reclaims',
          to: { name: 'reclaims-list' },
        },
        {
          text: 'Reclaim Org',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reclaim-organizations/signup',
    name: 'reclaim-organizations-signup',
    component: () => import('@/views/user-entities/signup-reclaiming-organization.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/reclaim-organizations/add',
    name: 'reclaim-organizations-add',
    component: () => import('@/views/user-entities/add-reclaiming-organization.vue'),
    meta: {
      resource: 'ScrappedEventController',
      action: 'store',
    },
  },
]
