export default [
  {
    path: '/organization/mobile-users/list',
    name: 'mobile-users-list',
    component: () => import('@/views/mobiles-users/organization/list.vue'),
    meta: {
      resource: 'UsersController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Mobile Users List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/mobile-users/list',
    name: 'mobile-users-admin-list',
    component: () => import('@/views/mobiles-users/admin/list.vue'),
    meta: {
      resource: 'global-users',
      action: 'manage-all',
      breadcrumb: [
        {
          text: 'Mobile Users List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mobile-users/view/:id',
    name: 'mobile-users-view',
    component: () => import('@/views/mobiles-users/view.vue'),
    meta: {
      resource: 'UsersController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Mobile Users List',
          to: 'back',
          levels: 1,
        },
        {
          text: 'View',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mobile-users/view/:id',
    name: 'admin-mobile-users-view',
    component: () => import('@/views/mobiles-users/view.vue'),
    meta: {
      resource: 'global-UsersController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Mobile Users List',
          to: 'back',
          levels: 1,
        },
        {
          text: 'View',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mobile-users/view/:id',
    name: 'poll-winners-view',
    component: () => import('@/views/mobiles-users/view.vue'),
    meta: {
      resource: 'global-PollController',
      action: 'getLiveWinners',
      breadcrumb: [
        {
          text: 'Mobile Users List',
          to: 'back',
          levels: 1,
        },
        {
          text: 'View',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mobile-users/otp',
    name: 'mobile-users-otp',
    component: () => import('@/views/mobiles-users/otp.vue'),
    meta: {
      resource: 'UsersController',
      action: 'getUserDetailsWithOTP',
      breadcrumb: [
        {
          text: 'OTP',
          active: true,
        },
      ],
    },
  },
]
