import Vue from 'vue'
import store from '@/store'
import router from '@/router'

export default function signOut() {
  const removeUserData = () => {
    store.commit('auth/setUser', '')

    localStorage.removeItem('notificationToken')
    localStorage.removeItem('userData')
  }

  const removeAccessToken = () => {
    store.commit('auth/setToken', '')

    localStorage.removeItem('accessToken')
  }

  const removePermissions = () => {
    const guestPermissions = [{
      subject: 'Auth',
      action: 'read',
    }]

    Vue.prototype.$ability.update(guestPermissions)
    localStorage.removeItem('abilities')
    localStorage.removeItem('globalAbilities')
  }
  const removeMainEntity = () => {
    store.commit('mainEntity/setEntityId', '')
    store.commit('mainEntity/setEntityName', 'No Entity')
    store.commit('mainEntity/setEntityType', '')

    localStorage.removeItem('entityId')
    localStorage.removeItem('entityName')
    localStorage.removeItem('entityType')
  }

  const logout = () => {
    removeUserData()
    removeAccessToken()
    removePermissions()
    removeMainEntity()
    router.push('/login')
  }

  return {
    logout,
  }
}
