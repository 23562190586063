export default [
  {
    path: '/attendance/scan-qr',
    name: 'scan-qr',
    component: () => import('@/views/event-attendance/scanQR.vue'),
    meta: {
      resource: 'PrayerBookingController',
      action: 'getBookingDetailsByQRCode',
      breadcrumb: [
        {
          text: 'Scan QR',
          active: true,
        },
      ],
    },
  },
  {
    path: '/attendance/confirm-attendees/:QRCode',
    name: 'confirm-attendance',
    component: () => import('@/views/event-attendance/confirmAttendance.vue'),
    meta: {
      resource: 'PrayerBookingController',
      action: 'verifyBookingAttendance',
      breadcrumb: [
        {
          text: 'Scan QR',
          to: { name: 'scan-qr' },
        },
        {
          text: 'Confirm Attendance',
          active: true,
        },
      ],
    },
  },
]
