import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    eventsList: [],
  },
  getters: {
    getEventsList(state) {
      return state.eventsList
    },
  },
  mutations: {
    setEventsList(state, events) {
      state.eventsList = events
    },
  },
  actions: {
    createEvent({ commit }, data) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$activities.post('internalops/prayer-activities', data)
          .then(res => {
            commit('setEventsList', res.data.data)
            resolve(res)
          }).catch(err => {
            reject(err)
          })
      })
    },
  },
}
