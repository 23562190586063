export default [
  {
    path: '/transactions/list',
    name: 'transactions-list',
    component: () => import('@/views/transactions/list.vue'),
    meta: {
      resource: 'TransactionController',
      action: 'index',
      breadcrumb: [
        {
          text: 'Transactions',
          active: true,
        },
      ],
    },
  },
  {
    path: '/donations/list',
    name: 'donations-list',
    component: () => import('@/views/donation-management/list.vue'),
    meta: {
      resource: 'UsersController',
      action: 'getUserDonationForEntity',
      breadcrumb: [
        {
          text: 'Donations',
          active: true,
        },
      ],
    },
  },
  {
    path: '/donations/add-donor',
    name: 'add-donor',
    component: () => import('@/views/donation-management/add-donor.vue'),
    meta: {
      resource: 'UsersController',
      action: 'addDonor',
      breadcrumb: [
        {
          text: 'Donations',
          to: { name: 'donations-list' },
        },
        {
          text: 'Add Donor',
          active: true,
        },
      ],
    },
  },
  {
    path: '/donations/edit-donor/:id',
    name: 'edit-donor',
    component: () => import('@/views/donation-management/edit-donor.vue'),
    meta: {
      resource: 'DonorController',
      action: 'update',
      breadcrumb: [
        {
          text: 'Donations',
          to: { name: 'donations-list' },
        },
        {
          text: 'Edit Donor',
          active: true,
        },
      ],
    },
  },
  {
    path: '/donations/add-donation/:user_id',
    name: 'add-donation',
    component: () => import('@/views/donation-management/add-donation.vue'),
    meta: {
      resource: 'TransactionController',
      action: 'addDonationTransaction',
      breadcrumb: [
        {
          text: 'Donations',
          to: { name: 'donations-list' },
        },
        {
          text: 'Add Donation',
          active: true,
        },
      ],
    },
  },
]
