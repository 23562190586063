export default [
  {
    path: '/prayer-bookings/list',
    name: 'prayer-bookings-list',
    component: () => import('@/views/prayer-bookings/list.vue'),
    meta: {
      resource: 'PrayerBookingController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Events',
          active: true,
        },
      ],
    },
  },
  {
    path: '/prayer-bookings/add',
    name: 'prayer-bookings-add',
    component: () => import('@/views/prayer-bookings/control-prayer-bookings/addPrayerBooking.vue'),
    meta: {
      resource: 'PrayerBookingController',
      action: 'store',
      breadcrumb: [
        {
          text: 'Events',
          to: { name: 'prayer-bookings-list' },
        },
        {
          text: 'Add Event',
          active: true,
        },
      ],
    },
  },
  {
    path: '/prayer-bookings/edit/:id',
    name: 'prayer-bookings-edit',
    component: () => import('@/views/prayer-bookings/control-prayer-bookings/editPrayerBooking.vue'),
    meta: {
      resource: 'PrayerBookingController',
      action: 'update',
      breadcrumb: [
        {
          text: 'Events',
          to: { name: 'prayer-bookings-list' },
        },
        {
          text: 'Edit Event',
          active: true,
        },
      ],
    },
  },
  {
    path: '/prayer-bookings/view/:id',
    name: 'prayer-bookings-view',
    component: () => import('@/views/prayer-bookings/view.vue'),
    meta: {
      resource: 'PrayerBookingController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Events',
          to: { name: 'prayer-bookings-list' },
        },
        {
          text: 'View Event',
          active: true,
        },
      ],
    },
  },
  {
    path: '/prayer-bookings/sessions/view/:id',
    name: 'sessions-view',
    component: () => import('@/views/prayer-bookings/sessions/view.vue'),
    meta: {
      resource: 'PrayerBookingController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Events',
          to: { name: 'prayer-bookings-list' },
        },
        {
          text: 'View Event',
          to: { name: 'prayer-bookings-view' },
        },
        {
          text: 'Event Sessions',
          active: true,
        },
      ],
    },
  },
  {
    path: '/prayer-bookings/requests/list',
    name: 'booking-requests',
    component: () => import('@/views/booking-requests/list.vue'),
    meta: {
      resource: 'BookingActionController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Booking Requests',
          active: true,
        },
      ],
    },
  },
]
