export default [
  {
    path: '/mobile-updates/update',
    name: 'add-mobile-update',
    component: () => import('@/views/mobile-updates/edit.vue'),
    meta: {
      resource: 'global-mobileUpdates',
      action: 'control',
      breadcrumb: [
        {
          text: 'Mobile Updates',
          active: true,
        },
      ],
    },
  },
]
