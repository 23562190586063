import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import signOut from '@/common/compositions/common/signOut'
import handleAlerts from '@/common/compositions/common/handleAlerts'

const { logout } = signOut()
const { showErrors } = handleAlerts()

const requestIntercept = config => {
  const { username } = JSON.parse(localStorage.getItem('userData')) ?? ''
  const token = `Bearer ${localStorage.getItem('accessToken')}`

  config.headers.Authorization = token
  config.headers.username = username
  config.headers.entityId = store.getters['mainEntity/getEntityId']

  return config
}

const errorResponseIntercept = error => {
  if (error.response.data instanceof Blob) {
    error.response.data.text().then(res => {
      showErrors(JSON.parse(res).errors)
    })
  } else if (error.response.data?.errors) { showErrors(error.response.data.errors) }

  const NOT_AUTHORIZED_STATUS = 401
  if (error.response.status === NOT_AUTHORIZED_STATUS) {
    logout()
  }
  throw error
}

const http = axios.create({})

const prayers = axios.create({
  baseURL: process.env.VUE_APP_PRAYERS_BASE_URL,
})

const activities = axios.create({
  baseURL: process.env.VUE_APP_ACTIVITIES_BASE_URL,
})

const entities = axios.create({
  baseURL: process.env.VUE_APP_ENTITIES_BASE_URL,
})
const users = axios.create({
  baseURL: process.env.VUE_APP_USERS_BASE_URL,
})
const portalUsers = axios.create({
  baseURL: process.env.VUE_APP_USERS_BASE_URL,
  validateStatus(status) {
    return status < 400
  },
})

const payment = axios.create({
  baseURL: process.env.VUE_APP_PAYMENT_BASE_URL,
})

const notification = axios.create({
  baseURL: process.env.VUE_APP_NOTIFICATIONS_BASE_URL,
})

http.interceptors.request.use(requestIntercept)
activities.interceptors.request.use(requestIntercept)
entities.interceptors.request.use(requestIntercept)
portalUsers.interceptors.request.use(requestIntercept)
payment.interceptors.request.use(requestIntercept)
notification.interceptors.request.use(requestIntercept)
users.interceptors.request.use(requestIntercept)

http.interceptors.response.use(response => response, errorResponseIntercept)
activities.interceptors.response.use(response => response, errorResponseIntercept)
entities.interceptors.response.use(response => response, errorResponseIntercept)
portalUsers.interceptors.response.use(response => response, errorResponseIntercept)
payment.interceptors.response.use(response => response, errorResponseIntercept)
notification.interceptors.response.use(response => response, errorResponseIntercept)
users.interceptors.response.use(response => response, errorResponseIntercept)

Vue.prototype.$http = http
Vue.prototype.$prayers = prayers
Vue.prototype.$activities = activities
Vue.prototype.$portalUsers = portalUsers
Vue.prototype.$entities = entities
Vue.prototype.$payment = payment
Vue.prototype.$notification = notification
Vue.prototype.$users = users

export default {
  prayers, activities, portalUsers, entities, payment, notification, http, users,
}
