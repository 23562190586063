export default [
  {
    path: '/suggested-events/list',
    name: 'suggested-events-list',
    component: () => import('@/views/suggested-events/list.vue'),
    meta: {
      resource: 'global-ScrappedEventController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Suggested Events',
          active: true,
        },
      ],
    },
  },
  {
    path: '/suggested-events/:id/view',
    name: 'suggested-event-view',
    component: () => import('@/views/suggested-events/view.vue'),
    meta: {
      resource: 'global-ScrappedEventController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Suggested Events',
          to: { name: 'suggested-events-list' },
        },
        {
          text: 'View Suggested Event',
          active: true,
        },
      ],
    },
  },
  {
    path: '/suggested-events/:id/original/view',
    name: 'original-suggested-event-view',
    component: () => import('@/views/suggested-events/view-original.vue'),
    meta: {
      resource: 'global-ScrappedEventController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Suggested Events',
          to: { name: 'suggested-events-list' },
        },
        {
          text: 'View Suggested Event',
          active: true,
        },
      ],
    },
  },
  {
    path: '/suggested-events/:id/edit',
    name: 'edit-suggested-event',
    component: () => import('@/views/community-events/edit.vue'),
    meta: {
      resource: 'global-ScrappedEventController',
      action: 'update',
      breadcrumb: [
        {
          text: 'Suggested Events',
          to: { name: 'suggested-events-list' },
        },
        {
          text: 'Edit Suggested Event',
          active: true,
        },
      ],
    },
  },
  {
    path: '/suggested-events/:id/notes',
    name: 'suggested-events-notes',
    component: () => import('@/views/suggested-events/notes.vue'),
    meta: {
      resource: 'global-CommunityOfferController',
      action: 'update',
      breadcrumb: [
        {
          text: 'Suggested Events',
          to: { name: 'suggested-events-list' },
        },
        {
          text: 'Suggested Offer Notes',
          active: true,
        },
      ],
    },
  },
]
