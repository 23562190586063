import axiosInstances from '@/libs/axios-instances'

export default function Notifications() {
  const addNotificationToken = () => axiosInstances.portalUsers.post('v1/notification/token', {
    deviceNotificationToken: localStorage.getItem('notificationToken'),
  })

  const getUserNotificationsList = () => axiosInstances.portalUsers.get('/v1/user/notification').then(res => res)
  const markNotificationAsReadRequest = id => axiosInstances.portalUsers.post(`/v1/user/notification/${id}/read`, {}).then(res => res)
  const markAllAsReadRequest = () => axiosInstances.portalUsers.post('/v1/user/notification/read', {}).then(res => res)

  return {
    addNotificationToken, getUserNotificationsList, markNotificationAsReadRequest, markAllAsReadRequest,
  }
}
