import Vue from 'vue'
import VueCryptojs from 'vue-cryptojs'

Vue.use(VueCryptojs)

export function setEncryptedItem(name, item) {
  const itemString = JSON.stringify(item)

  const encryptedItem = Vue.prototype.$CryptoJS.AES.encrypt(
    itemString,
    process.env.VUE_APP_SECRET_KEY,
  ).toString()

  localStorage.setItem(name, encryptedItem)
}

export function getEncryptedItem(name) {
  const item = localStorage.getItem(name) || ''

  const decryptedItem = Vue.prototype.$CryptoJS.AES.decrypt(
    item,
    process.env.VUE_APP_SECRET_KEY,
  ).toString(Vue.prototype.CryptoJS.enc.Utf8)

  const parsedItem = JSON.parse(decryptedItem || null)

  return parsedItem
}
