export default [
  {
    path: '/entity-profile',
    name: 'entity-profile',
    component: () => import('@/views/entity-profile/view.vue'),
    meta: {
      resource: 'EntitiesController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Entity Profile',
          active: true,
        },
      ],
    },
  },
]
