export default [
  {
    path: '/azkarCategory/list',
    name: 'azkarCategory-list',
    component: () => import('@/views/azkarCategory/list.vue'),
    meta: {
      resource: 'global-AzkarCategoryController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Azkar Categories',
          active: true,
        },
      ],
    },
  },
  {
    path: '/azkarCategory/add',
    name: 'azkarCategory-add',
    component: () => import('@/views/azkarCategory/add.vue'),
    meta: {
      resource: 'global-AzkarCategoryController',
      action: 'store',
      breadcrumb: [
        {
          text: 'Azkar Categories',
          to: { name: 'azkarCategory-list' },
        },
        {
          text: 'Add category',
          active: true,
        },
      ],
    },
  },
  {
    path: '/azkarCategory/:id/edit',
    name: 'azkarCategory-edit',
    component: () => import('@/views/azkarCategory/edit.vue'),
    meta: {
      resource: 'global-AzkarCategoryController',
      action: 'update',
      breadcrumb: [
        {
          text: 'Azkar Categories',
          to: { name: 'azkarCategory-list' },
        },
        {
          text: 'Edit Category',
          active: true,
        },
      ],
    },
  },
  {
    path: '/azkarCategory/:id/view',
    name: 'azkarCategory-view',
    component: () => import('@/views/azkarCategory/view.vue'),
    meta: {
      resource: 'global-AzkarCategoryController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Azkar Categories',
          to: { name: 'azkarCategory-list' },
        },
        {
          text: 'View category',
          active: true,
        },
      ],
    },
  },

]
