export default [
  {
    path: '/admin/notifications/list',
    name: 'list-notifications',
    component: () => import('@/views/notifications/admin/list.vue'),
    meta: {
      resource: 'global-adminNotifications',
      action: 'show',
      breadcrumb: [
        {
          text: 'Notifications',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/notifications/add',
    name: 'add-notifications',
    component: () => import('@/views/notifications/admin/add.vue'),
    meta: {
      resource: 'global-NotificationTokenController',
      action: 'sendToListOfUsers',
      breadcrumb: [
        {
          text: 'Notifications',
          to: { name: 'list-notifications' },
        },
        {
          text: 'Add Notification',
          active: true,
        },
      ],
    },
  },
  {
    path: '/organization/notifications/list',
    name: 'organizations-notifications-list',
    component: () => import('@/views/notifications/organization/list.vue'),
    meta: {
      resource: 'NotificationController',
      action: 'showEntityNotifications',
      breadcrumb: [
        {
          text: 'Notifications',
          active: true,
        },
      ],
    },
  },
  {
    path: '/organization/notifications/add',
    name: 'organizations-notifications-add',
    component: () => import('@/views/notifications/organization/add.vue'),
    meta: {
      resource: 'NotificationTokenController',
      action: 'sendToListOfUsers',
      breadcrumb: [
        {
          text: 'Notifications',
          to: { name: 'organizations-notifications-list' },
        },
        {
          text: 'Add Notification',
          active: true,
        },
      ],
    },
  },
]
