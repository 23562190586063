import Vue from 'vue'
import userDefaultPermissions from '@/common/default-permissions/userDefaultPermissions'
import { setEncryptedItem } from '@/libs/secure-web-storage'
import permissionsParser from '@/common/compositions/common/permissionsParser'

export default {
  namespaced: true,
  state: {
    user: JSON.parse(localStorage.getItem('userData')) || {},
    token: localStorage.getItem('accessToken'),
    loginData: {
      endPoint: 'login',
    },
  },
  getters: {
    getUserLoginData(state) {
      return state.loginData
    },
    isLoggedIn(state) {
      return !!state.token
    },
    getUserData(state) {
      return state.user
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    setToken(state, token) {
      state.token = token
    },
  },
  actions: {
    login({ getters, dispatch }, userCredentials) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$portalUsers.post(`v1/${getters.getUserLoginData.endPoint}`,
          {
            ...userCredentials,
            user_type: 3,
          })
          .then(resp => {
            const { access_token, user, permissionGroups } = resp.data.data

            dispatch('setAccessToken', access_token)
            dispatch('setUserInfo', user)
            dispatch('applyPermissions', permissionGroups)

            resolve(resp)
          })
          .catch(err => {
            localStorage.removeItem('accessToken')
            reject(err)
          })
      })
    },
    applyPermissions(context, globalPermissionGroups) {
      const { formatPermissions } = permissionsParser()
      const globalPermissions = formatPermissions(globalPermissionGroups)
      const allPermissions = [...new Set([...userDefaultPermissions, ...globalPermissions])]

      Vue.prototype.$ability.update(allPermissions)

      setEncryptedItem('globalAbilities', globalPermissions)
      setEncryptedItem('abilities', allPermissions)
    },
    setAccessToken({ commit }, accessToken) {
      commit('setToken', accessToken)
      localStorage.setItem('accessToken', accessToken)
    },
    setUserInfo({ commit }, userData) {
      commit('setUser', userData)
      localStorage.setItem('userData', JSON.stringify(userData))
    },
  },
}
