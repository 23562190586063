export default [
  {
    path: '/individual-users/signup',
    name: 'individual-users-signup',
    component: () => import('@/views/user-entities/add-individual.vue'),
    meta: {
      resource: 'Auth',
      action: 'read',
      layout: 'full',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/organization-users/signup',
    name: 'organization-users-signup',
    component: () => import('@/views/user-entities/add-organization.vue'),
    meta: {
      resource: 'Auth',
      action: 'read',
      layout: 'full',
      redirectIfLoggedIn: true,
    },
  },
]
