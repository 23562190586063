export default [
  {
    path: '/permissions/admin/list',
    name: 'admin-users-list',
    component: () => import('@/views/permissions/admin/users/list.vue'),
    meta: {
      resource: 'global-permissions',
      action: 'manage-permissions',
      breadcrumb: [
        {
          text: 'MuslimDo Admin Staff',
          active: true,
        },
      ],
    },
  },
  {
    path: '/permissions/admin/edit-user-permissions/:username',
    name: 'admin-edit-user-permissions',
    component: () => import('@/views/permissions/admin/users/edit-user-permission.vue'),
    meta: {
      resource: 'global-permissions',
      action: 'manage-permissions',
      breadcrumb: [
        {
          text: 'Admin Staff',
          to: { name: 'admin-users-list' },
        },
        {
          text: 'Edit Admin Permissions',
          active: true,
        },
      ],
    },
  },
  {
    path: '/permissions/admin/roles',
    name: 'admin-roles-list',
    component: () => import('@/views/permissions/admin/roles/list.vue'),
    meta: {
      resource: 'global-permissions',
      action: 'manage-permissions',
      breadcrumb: [
        {
          text: 'Roles',
          active: true,
        },
      ],
    },
  },
  {
    path: '/permissions/admin/add-member',
    name: 'admin-add-member',
    component: () => import('@/views/permissions/admin/users/add-member.vue'),
    meta: {
      resource: 'global-permissions',
      action: 'manage-permissions',
      breadcrumb: [
        {
          text: 'Staff',
          to: { name: 'users-list' },
        },
        {
          text: 'Add MuslimDo Member',
          active: true,
        },
      ],
    },
  },
  {
    path: '/permissions/admin/assign-member',
    name: 'admin-assign-member',
    component: () => import('@/views/permissions/admin/users/assign-member.vue'),
    meta: {
      resource: 'global-permissions',
      action: 'manage-permissions',
      breadcrumb: [
        {
          text: 'Staff',
          to: { name: 'admin-users-list' },
        },
        {
          text: 'Assign MuslimDo Member',
          active: true,
        },
      ],
    },
  },
  {
    path: '/permissions/admin/edit-user-roles/:username',
    name: 'admin-edit-user-roles',
    component: () => import('@/views/permissions/admin/users/edit-user-role.vue'),
    meta: {
      resource: 'global-permissions',
      action: 'manage-permissions',
      breadcrumb: [
        {
          text: 'Staff',
          to: { name: 'admin-users-list' },
        },
        {
          text: 'Edit Admin Role',
          active: true,
        },
      ],
    },
  },
  {
    path: '/permissions/admin/edit-role-permissions/:roleId',
    name: 'admin-edit-role-permissions',
    component: () => import('@/views/permissions/admin/roles/edit-role-permissions.vue'),
    meta: {
      resource: 'global-permissions',
      action: 'manage-permissions',
      breadcrumb: [
        {
          text: 'Roles',
          to: { name: 'admin-roles-list' },
        },
        {
          text: 'Edit Role Permissions',
          active: true,
        },
      ],
    },
  },
  {
    path: '/permissions/admin/create-role',
    name: 'admin-create-role',
    component: () => import('@/views/permissions/admin/roles/create-role.vue'),
    meta: {
      resource: 'global-permissions',
      action: 'manage-permissions',
      breadcrumb: [
        {
          text: 'Roles',
          to: { name: 'admin-roles-list' },
        },
        {
          text: 'Add Role',
          active: true,
        },
      ],
    },
  },
  {
    path: '/permissions/admin/edit-role/:roleId',
    name: 'admin-edit-role',
    component: () => import('@/views/permissions/admin/roles/edit-role.vue'),
    meta: {
      resource: 'global-permissions',
      action: 'manage-permissions',
      breadcrumb: [
        {
          text: 'Roles',
          to: { name: 'admin-roles-list' },
        },
        {
          text: 'Edit Role',
          active: true,
        },
      ],
    },
  },
]
