export default [
  {
    path: '/live/:liveId/activity/:activityId/poll/list',
    name: 'polls-list',
    component: () => import('@/views/live-management/polls/list.vue'),
    meta: {
      resource: 'global-PollController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Lives',
          to: { name: 'live-management-list' },
        },
        {
          text: 'Questions',
          active: true,
        },
      ],
    },
  },
  {
    path: '/live/:liveId/activity/:activityId/poll/add',
    name: 'add-poll',
    component: () => import('@/views/live-management/polls/addPoll.vue'),
    meta: {
      resource: 'global-PollController',
      action: 'store',
      breadcrumb: [
        {
          text: 'Questions',
          to: { name: 'polls-list' },
        },
        {
          text: 'Add Question',
          active: true,
        },
      ],
    },
  },
  {
    path: '/live/:liveId/activity/:activityId/poll/edit/:id',
    name: 'edit-poll',
    component: () => import('@/views/live-management/polls/editPoll.vue'),
    meta: {
      resource: 'global-PollController',
      action: 'update',
      breadcrumb: [
        {
          text: 'Questions',
          to: { name: 'polls-list' },
        },
        {
          text: 'Edit Question',
          active: true,
        },
      ],
    },
  },
  {
    path: '/live/:liveId/activity/:activityId/poll/winners/list',
    name: 'winners-list',
    component: () => import('@/views/live-management/polls/winners/list.vue'),
    meta: {
      resource: 'global-PollController',
      action: 'getLiveWinners',
      breadcrumb: [
        {
          text: 'Lives',
          to: { name: 'live-management-list' },
        },
        {
          text: 'Questions',
          to: 'back',
          levels: 1,
        },
        {
          text: 'View Answers',
          active: true,
        },
      ],
    },
  },
  {
    path: '/poll/sendWinningMessage/:usersId',
    name: 'send-winning-message',
    component: () => import('@/views/live-management/polls/winners/sendWinningMessage.vue'),
    meta: {
      resource: 'global-PollController',
      action: 'getLiveWinners',
      breadcrumb: [
        {
          text: 'Lives',
          to: { name: 'live-management-list' },
        },
        {
          text: 'Questions',
          to: 'back',
          levels: 2,
        },
        {
          text: 'View Answers',
          to: 'back',
          levels: 1,
        },
        {
          text: 'Send Message',
          active: true,
        },
      ],
    },
  },
]
