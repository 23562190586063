export default [
  {
    path: '/azkar/list',
    name: 'azkar-list',
    component: () => import('@/views/azkar/list.vue'),
    meta: {
      resource: 'global-AzkarController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Azkar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/azkar/add',
    name: 'azkar-add',
    component: () => import('@/views/azkar/add.vue'),
    meta: {
      resource: 'global-AzkarController',
      action: 'store',
      breadcrumb: [
        {
          text: 'Azkar',
          to: { name: 'azkar-list' },
        },
        {
          text: 'Add zikr',
          active: true,
        },
      ],
    },
  },
  {
    path: '/azkar/:id/edit',
    name: 'azkar-edit',
    component: () => import('@/views/azkar/edit.vue'),
    meta: {
      resource: 'global-AzkarController',
      action: 'update',
      breadcrumb: [
        {
          text: 'azkar',
          to: { name: 'azkar-list' },
        },
        {
          text: 'Update azkar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/azkar/:id/view',
    name: 'azkar-view',
    component: () => import('@/views/azkar/view.vue'),
    meta: {
      resource: 'global-AzkarController',
      action: 'show',
      breadcrumb: [
        {
          text: 'azkar',
          to: { name: 'azkar-list' },
        },
        {
          text: 'View zikr',
          active: true,
        },
      ],
    },
  },

]
