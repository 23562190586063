export default [
  {
    path: '/live-management/list',
    name: 'live-management-list',
    component: () => import('@/views/live-management/list.vue'),
    meta: {
      resource: 'global-LiveController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Lives',
          active: true,
        },
      ],
    },
  },
  {
    path: '/live-management/add',
    name: 'add-live',
    component: () => import('@/views/live-management/control-live/addLive.vue'),
    meta: {
      resource: 'global-LiveController',
      action: 'store',
      breadcrumb: [
        {
          text: 'Lives',
          to: { name: 'live-management-list' },
        },
        {
          text: 'Add Live',
          active: true,
        },
      ],
    },
  },
  {
    path: '/live-management/edit/:id',
    name: 'edit-live',
    component: () => import('@/views/live-management/control-live/editLive.vue'),
    meta: {
      resource: 'global-LiveController',
      action: 'update',
      breadcrumb: [
        {
          text: 'Lives',
          to: { name: 'live-management-list' },
        },
        {
          text: 'Edit Live',
          active: true,
        },
      ],
    },
  },
  {
    path: '/live-management/view/:id',
    name: 'live-view',
    component: () => import('@/views/live-management/control-live/view.vue'),
    meta: {
      resource: 'global-LiveController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Lives',
          to: { name: 'live-management-list' },
        },
        {
          text: 'View Live',
          active: true,
        },
      ],
    },
  },
  {
    path: '/live-management/session/:sessionId/comments/:id',
    name: 'live-comments',
    component: () => import('@/views/live-management/comments.vue'),
    meta: {
      resource: 'global-LiveController',
      action: 'update',
      breadcrumb: [
        {
          text: 'Lives',
          to: { name: 'live-management-list' },
        },
        {
          text: 'Live Comments',
          active: true,
        },
      ],
    },
  },
  {
    path: '/live-management/reports/:id',
    name: 'live-comments-reports',
    component: () => import('@/views/live-management/reportedComments.vue'),
    meta: {
      resource: 'global-LiveController',
      action: 'update',
      breadcrumb: [
        {
          text: 'Lives',
          to: { name: 'live-management-list' },
        },
        {
          text: 'Reported Comments',
          active: true,
        },
      ],
    },
  },
]
