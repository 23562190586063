export default [
  {
    path: '/offers/list',
    name: 'offers-list',
    component: () => import('@/views/offers/list.vue'),
    meta: {
      resource: 'global-CommunityOfferController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Offers',
          active: true,
        },
      ],
    },
  },
  {
    path: '/offers/add',
    name: 'offers-add',
    component: () => import('@/views/offers/add.vue'),
    meta: {
      resource: 'global-CommunityOfferController',
      action: 'store',
      breadcrumb: [
        {
          text: 'Offers',
          to: { name: 'offers-list' },
        },
        {
          text: 'Add Offer',
          active: true,
        },
      ],
    },
  },
  {
    path: '/offers/:id/edit',
    name: 'offers-edit',
    component: () => import('@/views/offers/edit.vue'),
    meta: {
      resource: 'global-CommunityOfferController',
      action: 'update',
      breadcrumb: [
        {
          text: 'Offers',
          to: { name: 'offers-list' },
        },
        {
          text: 'Update Offer',
          active: true,
        },
      ],
    },
  },
  {
    path: '/offers/:id/view',
    name: 'offers-view',
    component: () => import('@/views/offers/view.vue'),
    meta: {
      resource: 'global-CommunityOfferController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Offers',
          to: { name: 'offers-list' },
        },
        {
          text: 'View Offer',
          active: true,
        },
      ],
    },
  },
  {
    path: '/offers/:id/feedback-reports',
    name: 'offers-feedback-reports',
    component: () => import('@/views/offers/feedback-reports.vue'),
    meta: {
      resource: 'global-CommunityOfferController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Offers',
          to: { name: 'offers-list' },
        },
        {
          text: 'Offer Feedback Reports',
          active: true,
        },
      ],
    },
  },
]
