export default [
  {
    path: '/business/list',
    name: 'business-list',
    component: () => import('@/views/business/list.vue'),
    meta: {
      resource: 'global-EntitiesController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Business',
          active: true,
        },
      ],
    },
  },
  {
    path: '/business/add',
    name: 'add-business',
    component: () => import('@/views/business/control-business/addBusiness.vue'),
    meta: {
      resource: 'global-EntitiesController',
      action: 'store',
      breadcrumb: [
        {
          text: 'Business List',
          to: { name: 'business-list' },
        },
        {
          text: 'Add Business',
          active: true,
        },
      ],
    },
  },
  {
    path: '/business/edit/:id',
    name: 'edit-business',
    component: () => import('@/views/business/control-business/editBusiness.vue'),
    meta: {
      resource: 'EntitiesController',
      action: 'update',
      breadcrumb: [
        {
          text: 'Business List',
          to: { name: 'business-list' },
        },
        {
          text: 'Edit Business',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/business/edit/:id',
    name: 'admin-edit-business',
    component: () => import('@/views/business/control-business/editBusiness.vue'),
    meta: {
      resource: 'global-EntitiesController',
      action: 'update',
      breadcrumb: [
        {
          text: 'Business List',
          to: { name: 'business-list' },
        },
        {
          text: 'Edit Business',
          active: true,
        },
      ],
    },
  },
  {
    path: '/business/view/:id',
    name: 'view-business',
    component: () => import('@/views/business/view.vue'),
    meta: {
      resource: 'global-EntitiesController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Business List',
          to: { name: 'business-list' },
        },
        {
          text: 'View Business',
          active: true,
        },
      ],
    },
  },
]
