export default {
  namespaced: true,
  state: {
    entityId: JSON.parse(localStorage.getItem('entityId')) || '',
    entityName: localStorage.getItem('entityName'),
    entityType: localStorage.getItem('entityType'),
  },
  getters: {
    getEntityId(state) {
      return state.entityId
    },
    getEntityType(state) {
      return state.entityType
    },
    getEntityName(state) {
      return state.entityName
    },
  },
  mutations: {
    setEntityId(state, id) {
      state.entityId = id
    },
    setEntityType(state, type) {
      state.entityType = type
    },
    setEntityName(state, name) {
      state.entityName = name
    },
  },
}
