export default [
  {
    path: '/topics-calendar/list',
    name: 'topics-calendar-list',
    component: () => import('@/views/topics-calendar/list.vue'),
    meta: {
      resource: 'global-CalendarTopicController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Topics Calendar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/topics-calendar/add',
    name: 'topic-add',
    component: () => import('@/views/topics-calendar/addTopic.vue'),
    meta: {
      resource: 'global-CalendarTopicController',
      action: 'store',
      breadcrumb: [
        {
          text: 'Topics Calendar',
          to: { name: 'topics-calendar-list' },
        },
        {
          text: 'Add Topics',
          active: true,
        },
      ],
    },
  },
  {
    path: '/topics-calendar/edit/:id',
    name: 'topic-edit',
    component: () => import('@/views/topics-calendar/editTopic.vue'),
    meta: {
      resource: 'global-CalendarTopicController',
      action: 'update',
      breadcrumb: [
        {
          text: 'Topics Calendar',
          to: { name: 'topics-calendar-list' },
        },
        {
          text: 'Edit Topics',
          active: true,
        },
      ],
    },
  },
  {
    path: '/topics-calendar/view/:id',
    name: 'topic-view',
    component: () => import('@/views/topics-calendar/view.vue'),
    meta: {
      resource: 'global-CalendarTopicController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Topics Calendar',
          to: { name: 'topics-calendar-list' },
        },
        {
          text: 'View Topic',
          active: true,
        },
      ],
    },
  },
]
