export default [
  {
    path: '/community-events/list',
    name: 'community-events-list',
    component: () => import('@/views/community-events/list.vue'),
    meta: {
      resource: 'global-ScrappedEventController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Community Events',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reclaimed-events/list',
    name: 'reclaimed-events-list',
    component: () => import('@/views/reclaimed-events/list.vue'),
    meta: {
      resource: 'ScrappedEventController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Reclaimed Events',
          active: true,
        },
      ],
    },
  },
  {
    path: '/community-events/add',
    name: 'add-community-event',
    component: () => import('@/views/community-events/add.vue'),
    meta: {
      resource: 'global-ScrappedEventController',
      action: 'store',
      breadcrumb: [
        {
          text: 'Community Events',
          to: { name: 'community-events-list' },
        },
        {
          text: 'Add Community Event',
          active: true,
        },
      ],
    },
  },
  {
    path: '/community-events/:id/edit',
    name: 'edit-community-event',
    component: () => import('@/views/community-events/edit.vue'),
    meta: {
      resource: 'global-ScrappedEventController',
      action: 'update',
      breadcrumb: [
        {
          text: 'Community Events',
          to: { name: 'community-events-list' },
        },
        {
          text: 'Edit Community Event',
          active: true,
        },
      ],
    },
  },
  {
    path: '/community-events/:id/view',
    name: 'view-community-event',
    component: () => import('@/views/community-events/view.vue'),
    meta: {
      resource: 'global-ScrappedEventController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Community Events',
          to: { name: 'community-events-list' },
        },
        {
          text: 'View Community Event',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reclaimed-events/:id/view',
    name: 'view-reclaimed-event',
    component: () => import('@/views/community-events/view.vue'),
    meta: {
      resource: 'ScrappedEventController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Reclaimed Events',
          to: { name: 'reclaimed-events-list' },
        },
        {
          text: 'View Reclaimed Event',
          active: true,
        },
      ],
    },
  },
  {
    path: '/community-events/:id/feedback-reports',
    name: 'community-event-feedback-reports',
    component: () => import('@/views/community-events/feedback-reports.vue'),
    meta: {
      resource: 'global-ScrappedEventController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Community Events',
          to: { name: 'community-events-list' },
        },
        {
          text: 'Community Event Feedback Reports',
          active: true,
        },
      ],
    },
  },
  {
    path: '/community-events/:id/attendees',
    name: 'community-event-attendees',
    component: () => import('@/views/community-events/attendees.vue'),
    meta: {
      resource: 'global-ScrappedEventController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Community Events',
          to: { name: 'community-events-list' },
        },
        {
          text: 'Community Event Attendees',
          active: true,
        },
      ],
    },
  },

]
