import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    languages: [],
    tags: [],
    donationCategories: [],
    classifications: [],
    subClassifications: [],
  },
  getters: {
    getLanguages(state) {
      return state.languages
    },
    getActiveLanguages(state) {
      return state.languages.filter(language => language.status !== false)
    },
    getTags(state) {
      return state.tags
    },
    getActiveTags(state) {
      return state.tags.filter(tag => tag.status !== false)
    },
    getDonationCategories(state) {
      return state.donationCategories
    },
    getActiveDonationCategories(state) {
      return state.donationCategories.filter(category => category.status !== 0)
    },
    getClassifications(state) {
      return state.classifications
    },
    getActiveClassifications(state) {
      return state.classifications.filter(classification => classification.status !== false)
    },
    getSubClassifications(state) {
      return state.subClassifications
    },
    getActiveSubClassifications(state) {
      return state.subClassifications.filter(subClassification => subClassification.status !== false)
    },
  },
  mutations: {
    setLanguages(state, languages) {
      state.languages = languages
    },
    setTags(state, tags) {
      state.tags = tags
    },
    setDonationCategories(state, donationCategories) {
      state.donationCategories = donationCategories
    },
    setClass(state, classifications) {
      state.classifications = classifications
    },
    setSubClass(state, subClass) {
      state.subClassifications = subClass
    },
  },
  actions: {
    getTagsRequest({ commit }) {
      Vue.prototype.$entities.get('internalops/tag?paginated=false')
        .then(response => {
          commit('setTags', response.data.data)
        })
    },
    getLanguagesRequest({ commit }) {
      Vue.prototype.$entities.get('internalops/languages?paginated=false').then(response => {
        commit('setLanguages', response.data.data)
      })
    },
    getClassificationRequest({ commit }) {
      Vue.prototype.$entities
        .get('internalops/classifications?paginated=false')
        .then(response => {
          commit('setClass', response.data.data)
        })
    },
    getClassSubClassificationRequest({ commit }, id) {
      Vue.prototype.$entities
        .get('internalops/sub-classifications?paginated=false', {
          params: {
            classification: id,
          },
        })
        .then(response => {
          commit('setSubClass', response.data.data)
        })
    },
    getDonationCategoriesRequest({ commit, rootGetters }) {
      Vue.prototype.$entities.get('internalops/donation-categories', {
        params: {
          paginated: false,
          typeFilter: 'master',
          custom_entity_id: rootGetters['mainEntity/getEntityId'],
        },
      })
        .then(response => {
          commit('setDonationCategories', response.data.data)
        })
    },
  },
}
