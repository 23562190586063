// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken } from 'firebase/messaging'
import Notifications from '@/common/compositions/Notifications/NotificationsAPI'
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const { addNotificationToken } = Notifications()
const firebaseConfig = {

  apiKey: 'AIzaSyBYIL416B2Tnu-YLrtDF_Ccs9UfDtfIbmg',

  authDomain: 'muslimdo-291622.firebaseapp.com',

  databaseURL: 'https://muslimdo-291622.firebaseio.com',

  projectId: 'muslimdo-291622',

  storageBucket: 'muslimdo-291622.appspot.com',

  messagingSenderId: '974079723072',

  appId: '1:974079723072:web:fe682c0e8dae4e5a76c1a5',

  measurementId: 'G-L3PT0GYJQ1',

}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = 'serviceWorker' in navigator ? getMessaging(app) : {}
function requestPermission() {
  Notification.requestPermission().then(permission => {
    if (permission === 'granted') {
      getToken(messaging, { vapidKey: 'BFyAnITAXq_R2lWAxW-n7k6KD6e8Tld5Toj6lK3cCEF8v4ohJNv7ApBadh-HBoh-nL61TCM_6SmjwU6GyP5m1x0' }).then(currentToken => {
        if (currentToken) {
          // Send the token to your server and update the UI if necessary
          localStorage.setItem('notificationToken', currentToken)
          addNotificationToken()
        }
      })
    }
  })
}
export { messaging, requestPermission }
