export default [
  {
    path: '/permissions/entity/portal-users',
    name: 'users-list',
    component: () => import('@/views/permissions/entity/users/list.vue'),
    meta: {
      resource: 'permissions',
      action: 'manage-permissions',
      breadcrumb: [
        {
          text: 'Staff',
          active: true,
        },
      ],
    },
  },
  {
    path: '/permissions/entity/roles',
    name: 'roles-list',
    component: () => import('@/views/permissions/entity/roles/list.vue'),
    meta: {
      resource: 'permissions',
      action: 'manage-permissions',
      breadcrumb: [
        {
          text: 'Roles',
          active: true,
        },
      ],
    },
  },
  {
    path: '/permissions/entity/assign-entity-member',
    name: 'assign-entity-member',
    component: () => import('@/views/permissions/entity/users/assign-member.vue'),
    meta: {
      resource: 'permissions',
      action: 'manage-permissions',
      breadcrumb: [
        {
          text: 'Staff',
          to: { name: 'users-list' },
        },
        {
          text: 'Assign Entity Member',
          active: true,
        },
      ],
    },
  },
  {
    path: '/permissions/entity/add-entity-member',
    name: 'add-entity-member',
    component: () => import('@/views/permissions/entity/users/add-member.vue'),
    meta: {
      resource: 'permissions',
      action: 'manage-permissions',
      breadcrumb: [
        {
          text: 'Staff',
          to: { name: 'users-list' },
        },
        {
          text: 'Add Entity Member',
          active: true,
        },
      ],
    },
  },
  {
    path: '/permissions/entity/edit-user-permissions/:username',
    name: 'edit-user-permissions',
    component: () => import('@/views/permissions/entity/users/edit-user-permission.vue'),
    meta: {
      resource: 'permissions',
      action: 'manage-permissions',
      breadcrumb: [
        {
          text: 'Staff',
          to: { name: 'users-list' },
        },
        {
          text: 'Edit User Permissions',
          active: true,
        },
      ],
    },
  },
  {
    path: '/permissions/entity/edit-user-roles/:username',
    name: 'edit-user-roles',
    component: () => import('@/views/permissions/entity/users/edit-user-role.vue'),
    meta: {
      resource: 'permissions',
      action: 'manage-permissions',
      breadcrumb: [
        {
          text: 'Staff',
          to: { name: 'users-list' },
        },
        {
          text: 'Edit User Role',
          active: true,
        },
      ],
    },
  },
  {
    path: '/permissions/entity/edit-role-permissions/:roleId',
    name: 'edit-role-permissions',
    component: () => import('@/views/permissions/entity/roles/edit-role-permissions.vue'),
    meta: {
      resource: 'permissions',
      action: 'manage-permissions',
      breadcrumb: [
        {
          text: 'Roles',
          to: { name: 'roles-list' },
        },
        {
          text: 'Edit Role Permissions',
          active: true,
        },
      ],
    },
  },
  {
    path: '/permissions/entity/create-role',
    name: 'create-role-in-entity',
    component: () => import('@/views/permissions/entity/roles/create-role.vue'),
    meta: {
      resource: 'permissions',
      action: 'manage-permissions',
      breadcrumb: [
        {
          text: 'Roles',
          to: { name: 'roles-list' },
        },
        {
          text: 'Add Role',
          active: true,
        },
      ],
    },
  },
  {
    path: '/permissions/entity/edit-role/:roleId',
    name: 'edit-role-in-entity',
    component: () => import('@/views/permissions/entity/roles/edit-role.vue'),
    meta: {
      resource: 'permissions',
      action: 'manage-permissions',
      breadcrumb: [
        {
          text: 'Roles',
          to: { name: 'roles-list' },
        },
        {
          text: 'Edit Role',
          active: true,
        },
      ],
    },
  },
]
