export default [
  {
    path: '/inpos/list',
    name: 'inpos-list',
    component: () => import('@/views/inpos/list.vue'),
    meta: {
      resource: 'global-EntitiesController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Islamic Organizations',
          active: true,
        },
      ],
    },
  },
  {
    path: '/entity-reports/list',
    name: 'entity-reports',
    component: () => import('@/views/entity-reports/list.vue'),
    meta: {
      resource: 'global-EntitiesController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Entity Reports',
          active: true,
        },
      ],
    },
  },
  {
    path: '/inpos/add',
    name: 'add-inpo',
    component: () => import('@/views/inpos/control-inpos/addInpo.vue'),
    meta: {
      resource: 'global-EntitiesController',
      action: 'store',
      breadcrumb: [
        {
          text: 'Islamic Organizations',
          to: { name: 'inpos-list' },
        },
        {
          text: 'Add Islamic Org',
          active: true,
        },
      ],
    },
  },
  {
    path: '/inpos/edit/:id',
    name: 'inpo-edit',
    component: () => import('@/views/inpos/control-inpos/editInpo.vue'),
    meta: {
      resource: 'EntitiesController',
      action: 'update',
      breadcrumb: [
        {
          text: 'Islamic Organizations',
          to: { name: 'inpos-list' },
        },
        {
          text: 'Edit Islamic Org',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/inpos/edit/:id',
    name: 'admin-inpo-edit',
    component: () => import('@/views/inpos/control-inpos/editInpo.vue'),
    meta: {
      resource: 'global-EntitiesController',
      action: 'update',
      breadcrumb: [
        {
          text: 'Islamic Organizations',
          to: { name: 'inpos-list' },
        },
        {
          text: 'Edit Islamic Org',
          active: true,
        },
      ],
    },
  },
  {
    path: '/inpos/payment-details/edit',
    name: 'payment-details-edit',
    component: () => import('@/views/inpos/control-inpos/editPaymentDetails.vue'),
    meta: {
      resource: 'EntityPaymentController',
      action: 'updatePaymentDetails',
      breadcrumb: [
        {
          text: 'Edit Payment Details',
          active: true,
        },
      ],
    },
  },
  {
    path: '/inpos/view/:id',
    name: 'view-inpo',
    component: () => import('@/views/inpos/view.vue'),
    meta: {
      resource: 'global-EntitiesController',
      action: 'show',
      breadcrumb: [
        {
          text: 'Islamic Organizations',
          to: { name: 'inpos-list' },
        },
        {
          text: 'View Islamic Org',
          active: true,
        },
      ],
    },
  },
]
